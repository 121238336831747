.Footer
{
    margin-top: 7vh;
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: auto;
    min-height: 15vh;
    background-color: #4885FF;
    color: whitesmoke;
    justify-content: center;
}
.Footer .Footer-Container
{
    width: 80vw;
    height: auto;
    margin: 0 10vw;
    text-align: center;
}
.Footer-Container a
{
    text-decoration: none;
    color: whitesmoke;
}
.Footer-Container a:hover
{
    color: orange;
}
.Footer-List
{
    display: inline;
    list-style: none;
    padding: 0.5vh 1vw;
}
.Footer-Container .Logo
{
    width: 100%;
}
.Logo .Logo-Image
{
    width: 16%;
}
@media screen and (min-width:375px) and (max-width:912px){
.Footer
{
    margin-top: 2vh;
    display: flex;
    flex-direction: column;
    width: 100vw;
    min-height: 10vh;
    background-color: #4885FF;
    color: whitesmoke;
    justify-content: center;
}
.Footer .Footer-Container
{
    width: 100vw;
    height: auto;
    margin: 0;
    text-align: center;
}
.Footer-Container .Logo
{
    width: 100%;
    height: auto;
    margin: 0.5vh 0;
}
.Logo .Logo-Image
{
    width: 35%;
}
.Footer-Container ul
{
    display: inline;
    list-style: none;
    padding: 0;
    font-size: 10px;
}
}