.Content
{
  width: 100vw;
  height: calc(100% - 50px);
  min-height: 84vh;
  max-height: auto;
  display: flex;
  background-color: white;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: auto;
}
.Content::-webkit-scrollbar {
  width: 2px;
}
 
.Content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
.Content::-webkit-scrollbar-thumb {
  background-color: blue;
  outline: 1px solid slategrey;
}
form
{
  padding: 30px 40px;
  background-color: white;
}
.form-control
{
  margin-bottom: 15px;
  padding-bottom: 20px;
  position: relative;
}
.form-control label
{
  display: inline-block;
  margin-bottom: 5px;
}
.form-control input
{
  border: none;
  border-bottom: 2px solid blue;
  background-color: white;
  padding: 5px;
  font-size: 14px;
  font-family: inherit;
  display: block;
  width: 100%;
}
form button
{
  color: whitesmoke;
  background: linear-gradient(to right, #66ccff 0%, #0000cc 100%);
  border: 1.5px solid rgb(90, 90, 250);
  border-radius: 10px;
  padding: 10px;
  font-size: 16px;
  font-family: inherit;
  display: block;
  width: auto;
}
@media only screen and (max-width: 750px){
  .Content
  {
    width: 100vw;
    height: calc(100% - 50px);
    max-height: 89vh;
    display: flex;
    background-color: white;
    justify-content: center;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .Content::-webkit-scrollbar {
    width: 2px;
  }
   
  .Content::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
   
  .Content::-webkit-scrollbar-thumb {
    background-color: blue;
    outline: 1px solid slategrey;
  }
  form
  {
    padding: 30px 40px;
    background-color: white;
  }
  .form-control
  {
    margin-bottom: 15px;
    padding-bottom: 20px;
    position: relative;
    transform: translateX(5vw);
    animation: move 1s forwards
  }
  @keyframes move {
    100%{
      transform: translateX(0vw)
    }
  }
  .form-control label
  {
    display: inline-block;
    margin-bottom: 5px;
  }
  .form-control input
  {
    border: none;
    border-bottom: 2px solid blue;
    background-color: white;
    padding: 5px;
    font-size: 14px;
    font-family: inherit;
    display: block;
    width: 100%;
  }
  form button
  {
    color: whitesmoke;
    background: linear-gradient(to right, #66ccff 0%, #0000cc 100%);
    border: 1.5px solid rgb(90, 90, 250);
    border-radius: 10px;
    padding: 10px;
    font-size: 16px;
    font-family: inherit;
    display: block;
    width: auto;
  } 
}