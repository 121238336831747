.Faq
{
  padding: 5px 50px;
  margin: auto;
  text-align: center;
}
.Faq-form
{
  text-align: left;
  font-size: 16px;
  padding: 0px 15px;
  word-spacing: 0.5em;
}
.Pop-Up
{
  width: 800px;
  height: auto;
  background-color: rgb(73, 73, 233);
  color:whitesmoke;
  border-radius: 10px;
  cursor: pointer;
}
.Pop-Up p
{
  padding: 5px 20px;
}
.Pop-Up .form-control
{
  background-color: whitesmoke;
  color: black;
  border: grey 2px solid;
  border-radius: 10px;
}
.Pop-Up .form-control-unview
{
  display: none;
}