.Product-List
{
    width: 92vw;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 2vh 0;
}
.Product-List a
{
    text-decoration: none;
    color: black;
}
.Product-List .List-Product
{
    width: 15vw;
    height: auto;
    background-color: white;
    border-radius: 10px;
    margin: 1vh 0.5vw;
    border: 1px solid #e1e1e1;
    transition: all 1s ease-in;
}
.Product-List .List-Product:hover
{
    box-shadow: 1px 1px 19px 10px rgba(218,224,232,1);
}
.List-Product
{
    display: flex;
    flex-direction: column;
}
.Product-Offer
{
    display: flex;
    flex-direction: row;
}
.Product-Offer .Offer
{
    width: 5.7vw;
    height: 3.5vh;
    background-color: white;
    text-align: center;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 0.5px;
    color: whitesmoke;
    background-color: green;
    padding: 0.8vh 0.5vw;
    box-shadow: 1px 1px 1px 1px whitesmoke;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.Product-Offer .Favorite-Icon
{
    display: flex;
    flex-direction: row-reverse;
    width: 9.3vw;
    height: 3.5vh;
    padding: 1vh 1vw 0 0;
    font-size: 24px;
    color: rgb(118, 118, 246);
    cursor: pointer;
}
.Product-Img
{
    width: 15vw;
    height: 20vh;
    text-align: center;
    padding: 1vh 0;
}
.Product-Img img
{
    width: 9vw;
}
.Product-Details
{
    width: 15vw;
    height: 12vh;
    padding: 1vh 1vw;
    font-size: 14px;
    font-family: 'Courier New', Courier, monospace;
}
.Product-Details .Product-Brand
{
    color: #999;
    font-size: 12px;
}
.Product-Select
{
    width: 14vw;
    height: 3.5vh;
    margin: 0 0.5vw;
}
.Product-Select div
{
    position: relative;
    width: 100%;
    height: 100%;
    border: 1px solid #e1e1e1;
    font-size: 14px;
    cursor: pointer;
}
.Popup-Unit
{
  position: absolute;
  width: 100%;
  margin: auto;
  border: 2px solid gray;
}
.Popup-Inner-Unit
{
  position: absolute;
  margin: auto;
  border-radius: 10px;
}
.Popup-Inner-Unit .Unit-Value
{
    width: 100%;
    height: auto;
    background-color: whitesmoke;
    color: black;
    border: 1px solid white;
    padding: 2px 5px;
    cursor: pointer;
}
.Popup-Inner-Unit .Unit-Value:hover
{
    color: rgb(118, 118, 246);
}
.Product-Rate
{
    width: 14vw;
    height: 10vh;
    margin: 0 0.5vw;
    padding: 0.5vh 1vw;
    background-color: whitesmoke;
}
.Product-Rate-Mrp
{
    font-size: 12px;
    color: #666;
}
.Product-Rate-Mrp .Mrp
{
    text-decoration: line-through;
    font-size: 11px;
    text-align: center;
    font-weight: bold;
}
.Product-Rate-Mrp .Selling
{
    font-size: 16px;
    color: #231f20;
}
.Quantity
{
    display: flex;
    flex-direction: row;
    padding: 1vh 0;
}
.Quantity .Out-Of-Stock
{
    display: flex;
    width: 100%;
    height: 4vh;
    justify-content: center;
    align-items: center;
    background-color: #FFC400;
    color: whitesmoke;
    border-radius: 5px;
}
.Quantity .Quantity-Input
{
    width: 5vw;
    height: 3.5vh;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    background-color: #eee;
}
.Quantity-Input span
{
    width: 40%;
    height: 100%;
    font-size: 12px;
    padding: 0 4px;
    color: #999;
}
.Quantity-Input input
{
    width: 60%;
    height: 100%;
    border: none;
    text-align: center;
    border-radius: 5px;
}
.Quantity .Quantity-Cart
{
    width: 5.5vw;
    height: 3.5vh;
    margin: 0 1vw;
}
.Cart-Button
{
    width: 100%;
    height: 100%;
    border: none;
    color: #414042;
    background: linear-gradient(180deg,#fee67c 0,#f2cb76);
    border-radius: 5px;
}
.Cart-Button:hover
{
    cursor: pointer;
}
@media screen and (min-width:375px) and (max-width:912px){
    .Product-List
    {
        width: 100vw;
        height: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .Product-List a
    {
        text-decoration: none;
        color: black;
    }  
    .Product-List .List-Product
    {
        width: 45vw;
        height: auto;
        background-color: white;
        border-radius: 5px;
        margin: 1vh 2.5vw;
        border: 1px solid #e1e1e1;
        transition: all 1s ease-in;
    } 
    .Product-List .List-Product:hover
    {
        box-shadow: 1px 1px 19px 10px rgba(218,224,232,1);
    }
    .List-Product
    {
        display: flex;
        flex-direction: column;
    }
    .Product-Offer .Offer
    {
        width: 16vw;
        height: 2.5vh;
        background-color: green;
        font-size: 7px;
        font-weight: bold;
        letter-spacing: 0.5px;
        color: whitesmoke;
        box-shadow: 1px 1px 1px 1px whitesmoke;
    }
    .Product-Offer .Favorite-Icon
    {
        display: flex;
        flex-direction: row-reverse;
        width: 27vw;
        height: 3.5vh;
        padding: 1vh 1vw 0 0;
        font-size: 24px;
        color: rgb(118, 118, 246);
        cursor: pointer;
    }
    .Product-Img
    {
        width: 45vw;
        height: 15vh;
        text-align: center;
        padding: 2vh 0;
    }
    .Product-Img img
    {
        width: 25vw;
    }
    .Product-Details
    {
        width: 45vw;
        height: 8vh;
        padding: 0 1vw;
        font-size: 12px;
        font-family: 'Courier New', Courier, monospace;
    }
    .Product-Details .Product-Brand
    {
        color: #999;
        font-size: 10px;
    }
    .Product-Select
    {
        width: 43vw;
        height: 3vh;
        margin: 0 0.5vw;
    }
    .Product-Select select
    {
        width: 100%;
        height: 100%;
        border: 1px solid #e1e1e1;
    }
    .Product-Rate
    {
        width: 43vw;
        height: 6vh;
        margin: 0 0.5vw;
        padding: 0.5vh 1vw;
        background-color: whitesmoke;
    }
    .Product-Rate-Mrp
    {
        font-size: 8px;
        color: #666;
    }
    .Product-Rate-Mrp .Mrp
    {
        text-decoration: line-through;
        font-size: 8px;
        text-align: center;
        font-weight: bold;
    }
    .Product-Rate-Mrp .Selling
    {
        font-size: 10px;
        color: #231f20;
    }
    .Quantity
    {
        display: flex;
        flex-direction: row;
        padding: 0.5vh 0;
    }
    .Quantity .Quantity-Input
    {
        width: 15vw;
        height: 2.5vh;
        border: 1px solid #e1e1e1;
        border-radius: 5px;
        background-color: #eee;
    }
    .Quantity-Input span
    {
        width: 40%;
        height: 100%;
        font-size: 10px;
        padding: 0 4px;
        color: #999;
    }
    .Quantity-Input input
    {
        width: 60%;
        height: 100%;
        border: none;
        text-align: center;
        border-radius: 5px;
    }
    .Quantity .Quantity-Cart
    {
        width: 10vw;
        height: 2.5vh;
        margin: 0 1vw;
    }
    .Cart-Button
    {
        width: 100%;
        height: 100%;
        border: none;
        color: #414042;
        background: linear-gradient(180deg,#fee67c 0,#f2cb76);
        border-radius: 5px;
        font-size: 8px;
    }
}