.Privacy
{
  padding: 5px 100px;
  text-align: center;
}
.Privacy-form 
{
  min-height: 50vh;
  font-size: 16px;
  padding: 0px 15px;
  text-indent: 60px;
  word-spacing: 0.5em;
  text-align: left;
}