.EmptyCart
{
    width: 80vw;
    height: auto;
    text-align: center;
    margin: 4vh 6vw;
}
.EmptyCart .Basket-Icon
{
    font-size: 165px;
    margin: 0;
    padding: 0;
    color: rgb(84, 84, 233);
}
.EmptyCart button
{
    background-color: rgb(84, 84, 233);
    border: none;
    color: whitesmoke;
    padding: 1vh 1vw;
    border-radius: 5px;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
}
.CartTable
{
    width: 70vw;
    height: auto;
    min-height: 48vh;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: auto;
    margin-top: 5vh;
}
.Cart-Total
{
    width: 100%;
    height: 14vh;
    background-color: whitesmoke;
    display: inline-flex;
    border-radius: 10px;
    align-items: center;
}
.Cart-Count-Savings
{
    width: 40%;
    color: black;
    font-size: 16px;
    text-align: center;
    padding: 0 1vw;
}
.Cart-Count-Savings span
{
    margin: 0.5vh 0.5vw;
    display: inline-block;
    padding: auto;
}
.Cart-Savings
{
    color: #91c733;
}
.Cart-Count-Savings span b
{
    font-size: 18px;
}
.Cart-Checkout-Buttons
{
    width: 60%;
    color: whitesmoke;
    font-size: 16px;
    text-align: right;
    padding: 0 2vw;
}
.Cart-Checkout-Buttons button
{
    background-color: #dc3545;
    --text-opacity: 1;
    color: rgba(255,255,255,var(--text-opacity));
    font-size: 1rem;
    padding: 1vh 3vw;
    margin: 0 0.5vw;
    font-weight: 300;
    letter-spacing: 0.25px;
    border-radius: 0.25rem;
    border: whitesmoke;
    cursor: pointer;
}
.Cart-Product-List
{
    margin: 4vh 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
}
.Cart-Product-Heading
{
    display: inline-flex;
    width: 100%;
    text-align: left;
    --text-opacity: 1;
    color: rgba(144,144,144,var(--text-opacity));
}
.Cart-Product-Heading .Heading-1
{
    width: 65%;
    padding: 0 1vw;
}
.Cart-Product-Heading .Heading-2
{
    width: 20%;
    text-align: left;
    padding: 0 2vw;
}
.Cart-Product-Heading .Heading-3
{
    width: 15%;
    text-align: right;
    padding: 0 2vw;
}
.Cart-Product
{
    display: inline-flex;
    width: 100%;
    height: 24vh;
    background-color: whitesmoke;
    border: 2px solid white;
    border-radius: 10px;
}
.Cart-Product .Content-1
{
    width: 65%;
    display: inline-flex;
    align-items: center;
}
.Content-1 .Cart-Product-Image
{
    width: 25%;
}
.Cart-Product-Image img
{
    width: 95%;
    padding: 0 1vw;
}
.Content-1 .Cart-Product-Details
{
    width: 75%;
    align-items: center;
    justify-content: center;
    text-align: left;
    padding: 0 3vw;
}
.Cart-Product-Name
{
    font-size: 17px;
    font-weight: bold;
}
.Cart-Product-Offer-Price
{
    font-size: 15px;
    color: #91c733;
}
.Cart-Product-Unit
{
    font-size: 15px;
    color: grey;
}
.Cart-Product .Content-2
{
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.Content-2 .Cart-Quantity
{
    width: 60%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 2px solid white;
    border-radius: 5px;
    background-color: white;
    cursor: pointer;
}
.Cart-Quantity div
{
    width: calc(100%/3);
    text-align: center;
    font-size: 18px;
    padding: 0.5vh 0.5vw;
}
.Cart-Product-Modify
{
    font-size: 12px;
    color: gray;
    font-weight: bold;
    cursor: pointer;
}
.Cart-Product-Modify span
{
    display: inline-block;
    padding: 5px 5px;
}
.Cart-Product-Modify span:hover
{
    color: rgb(84, 84, 233);
}
.Cart-Product .Content-3
{
    width: 15%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: right;
    margin: 0 2vw;
}
.Cart-SellingPrice
{
    font-size: 22px;
    font-weight: bold;
}
.Cart-Discount
{
    font-size: 14px;
    color: #91c733;
}