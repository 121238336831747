.Store
{
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: calc(100% - 50px);
    max-height: 100vh;
    margin: 0vh 10vw;
}
.Category
{
    width: 92vw;
    font-family: 'Source Sans Pro', sans-serif; 
    margin: 3vh 4vw;
}
.Category-Head
{
    color: rgb(68, 68, 68);
    text-align: center;
}
.Category-Head button
{
    padding: 1vh 1vw;
    border-radius: 5px;
    border: none;
    font-size: 16px;
    cursor: pointer;
    background-color: rgb(84, 84, 233);
    color: whitesmoke;
}
.Category h2
{
    line-height: 0;
    font-size: 24px;
}
.Category-List
{
    width: 92vw;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    --bg-opacity: 1;
    background-color: rgba(247,247,247,var(--bg-opacity));
    border-radius: 15px;
}
.Category-List a
{
    text-decoration: none;
    color: black;
}
.Category-List .List-Category
{
    width: 14vw;
    height: 30vh;
    background-color: white;
    border-radius: 10px;
    border: 1px solid #e1e1e1;
    margin: 2vh 0.5vw;
}
hr
{
    width: 90vw;
    border-top: 1px solid #e1e1e1;
}
.List-Category
{
    display: flex;
    flex-direction: column;
}
.List-Category .Category-Img
{
    margin: 1vh 0vw;
}
.List-Category .Category-Img img
{
    width: 13vw;
    height: 24vh;
}
.Category-Name
{
    width: 14vw;
    margin: 0;
    text-align: center;
    font-size: 12px;
    cursor: pointer;
}
.Category-Name :hover
{
    text-decoration: underline;
}
.Sub-Category
{
    margin: 1vh 4vw;
}
.Sub-Category-Head
{
    color: rgb(68, 68, 68);
    text-align: center;
}
.Sub-Category h2
{
    line-height: 0;
}
.ViewAll
{
    width: 88vw;
    text-align: right;
    font-size: 17px;
    color: blue;
    cursor: pointer;
    margin: 2vh 0 1vh 0;
}
.ViewAll button
{
    background-color: rgb(84, 84, 233);
    border: none;
    color: whitesmoke;
    padding: 0.5vh 0.5vw;
    cursor: pointer;
}
.ViewAll button :hover
{
    text-decoration: underline;
}
.Sub-Category-List
{
    width: 92vw;
    height: auto; 
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    --bg-opacity: 1;
    background-color: rgba(247,247,247,var(--bg-opacity));
    border-radius: 15px;
    padding: 0 0 2vh 0;
}
.Sub-Category-List a
{
    text-decoration: none;
    color: black;
}
.Sub-Category-List .List-Sub-Category
{
    width: 14vw;
    height: 30vh;
    margin: 1vh 0.5vw;
    background-color: white;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
}
.Sub-Category-Full-List
{
    width: 94vw;
    height: auto; 
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.Sub-Category-Full-List a
{
    text-decoration: none;
    color: black;
}
.Sub-Category-Full-List .List-Sub-Category
{
    width: 14vw;
    height: 30vh;
    margin: 1vh 0.5vw;
    background-color: white;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
}
.List-Sub-Category
{
    display: flex;
    flex-direction: column;
}
.List-Sub-Category .Sub-Category-Img
{
    margin: 1vh 0vw;
}
.List-Sub-Category .Sub-Category-Img img
{
    width: 13vw;
    height: 24vh;
}
.Sub-Category-Name
{
    width: 14vw;
    margin: 0;
    text-align: center;
    font-size: 12px;
    cursor: pointer;
}
.Sub-Category-Name :hover
{
    text-decoration: underline;
}
.Brand
{
    margin: 1vh 4vw;
}
.Brand-Head
{
    color: rgb(68, 68, 68);
    text-align: center;
}
.Brand h2
{
    line-height: 0;
}
.Brand-List
{
    width: 92vw;
    height: auto; 
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    --bg-opacity: 1;
    background-color: rgba(247,247,247,var(--bg-opacity));
    border-radius: 15px;
}
.Brand-List a
{
    text-decoration: none;
    color: black;
}
.Brand-List .List-Brand
{
    width: 14vw;
    height: 30vh;
    margin: 1vh 0.5vw;
    background-color: white;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
}
.Brand-Full-List
{
    width: 92vw;
    height: auto; 
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.Brand-Full-List a
{
    text-decoration: none;
    color: black;
}
.Brand-Full-List .List-Brand
{
    width: 14vw;
    height: 30vh;
    margin: 1vh 0.5vw;
    background-color: white;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
}
.List-Brand
{
    display: flex;
    flex-direction: column;
}
.List-Brand .Brand-Img
{
    margin: 2vh 2vw;
}
.List-Brand .Brand-Img img
{
    width: 10vw;
    height: 20vh;
}
.Brand-Name
{
    width: 14vw;
    margin: 0;
    text-align: center;
    font-size: 12px;
    cursor: pointer;
}
.Brand-Name :hover
{
    text-decoration: underline;
}
@media only screen and (max-width: 750px){
    .Store
    {
        display: flex;
        flex-direction: column;
        width: 100vw;
        height: calc(100% - 50px);
        max-height: 85vh;
    } 
    .Category
    {
        width: 100vw;
        background-color: white; 
        margin: 1vh 0vw;
    }
    .Category-Head
    {
        color: rgb(68, 68, 68);
        text-align: center;
    }
    .Category-Head p {
        font-size: 12px;
    }
    .Category h2
    {
        line-height: 0;
        font-size: 18px;
    }
    .Category-List
    {
        width: 100vw;
        height: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .Category-List a
    {
        text-decoration: none;
        color: black;
    }
    .Category-List .List-Category
    {
        width: 30vw;
        height: 15vh;
        background-color: white;
        border-radius: 5px;
        border: 1px solid #e1e1e1;
        margin: 0.5vh 0.5vw;
    }
    hr
    {
        width: 90vw;
        border-top: 1px solid #e1e1e1;
    }
    .List-Category
    {
        display: flex;
        flex-direction: column;
    }
    .List-Category .Category-Img
    {
        margin: 0.5vh;
    }
    .List-Category .Category-Img img
    {
        width: 28vw;
        height: 12vh;
    }
    .Category-Name
    {
        width: 28vw;
        height: 3vh;
        margin: 0.5vh 0;
        text-align: center;
        font-size: 8px;
        cursor: pointer;
    }
    .Category-Name :hover
    {
        text-decoration: underline;
    }
    .Sub-Category
    {
        margin: 0.5vh 0;
    }
    .Sub-Category-Head
    {
        color: rgb(68, 68, 68);
        text-align: center;
    }
    .Sub-Category h2
    {
        line-height: 0;
        font-size: 18px;
    }
    .Sub-Category-Head p{
        font-size: 12px;
    }
    .ViewAll
    {
        width: 95vw;
        text-align: right;
        font-size: 8px;
        color: blue;
        cursor: pointer;
    }
    .ViewAll button
    {
        background-color: rgb(84, 84, 233);
        border: none;
        color: whitesmoke;
    }
    .ViewAll button :hover
    {
        text-decoration: underline;
    }
    .Sub-Category-List
    {
        width: 100vw;
        height: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .Sub-Category-List a
    {
        text-decoration: none;
        color: black;
    }
    .Sub-Category-List .List-Sub-Category
    {
        width: 30vw;
        height: 15vh;
        background-color: white;
        border-radius: 5px;
        border: 1px solid #e1e1e1;
        margin: 0.5vh 0.5vw;
    }
    .Sub-Category-Full-List
    {
        width: 94vw;
        height: auto; 
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .Sub-Category-Full-List a
    {
        text-decoration: none;
        color: black;
    }
    .Sub-Category-Full-List .List-Sub-Category
    {
        width: 30vw;
        height: 15vh;
        margin: 0.5vh 0.5vw;
        background-color: white;
        border: 1px solid #e1e1e1;
        border-radius: 5px;
    }
    .List-Sub-Category
    {
        display: flex;
        flex-direction: column;
    }
    .List-Sub-Category .Sub-Category-Img
    {
        margin: 0vh 0vw;
    }
    .List-Sub-Category .Sub-Category-Img img
    {
        width: 28vw;
        height: 12vh;
    }
    .Sub-Category-Name
    {
        width: 30vw;
        height: 3vh;
        margin: 1vh 0;
        text-align: center;
        font-size: 8px;
        cursor: pointer;
    }
    .Sub-Category-Name :hover
    {
        text-decoration: underline;
    }
    .Brand
    {
        margin: 0.5vh 0;
    }
    .Brand-Head
    {
        color: rgb(68, 68, 68);
        text-align: center;
    }
    .Brand h2
    {
        line-height: 0;
        font-size: 18px;
    }
    .Brand-Head p
    {
        font-size: 12px;
    }
    .Brand-List
    {
        width: 100vw;
        height: auto; 
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .Brand-List a
    {
        text-decoration: none;
        color: black;
    }
    .Brand-List .List-Brand
    {
        width: 30vw;
        height: 15vh;
        background-color: white;
        border-radius: 5px;
        border: 1px solid #e1e1e1;
        margin: 0.5vh 0.5vw;
    }
    .Brand-Full-List
    {
        width: 100vw;
        height: auto; 
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .Brand-Full-List a
    {
        text-decoration: none;
        color: black;
    }
    .Brand-Full-List .List-Brand
    {
        width: 30vw;
        height: 15vh;
        margin: 0.5vh 0.5vw;
        background-color: white;
        border: 1px solid #e1e1e1;
        border-radius: 5px;
    }
    .List-Brand
    {
        display: flex;
        flex-direction: column;
    }
    .List-Brand .Brand-Img
    {
        margin: 0vh 0vw;
    }
    .List-Brand .Brand-Img img
    {
        width: 28vw;
        height: 12vh;
    }
    .Brand-Name
    {
        width: 28vw;
        height: 3vh;
        margin: 1vh 0;
        text-align: center;
        font-size: 8px;
        cursor: pointer;
    }
    .Brand-Name :hover
    {
        text-decoration: underline;
    }
}