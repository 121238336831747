.Product-View
{
    display: flex;
    flex-direction: row;
    width: 70vw;
    height: 65vh;
    margin: 0 12.5vw 12vh 12.5vw;
    border: 1px solid #e1e1e1;
}
.Product-View-Left
{
    width: 35vw;
    height: 65vh;
    display: flex;
    border-right: 1px solid #e1e1e1;
}
.Product-View-Left img
{
    width: 30vw;
    height: 60vh;
    margin: 2.5vh 2.5vw;
}
.Product-View-Right
{
    display: flex;
    flex-direction: column;
    width: 35vw;
    height: 65vh;
}
.Product-View-Details
{
    width: 35vw;
    height: auto;
    margin: 1vh 0;
    padding: 1vh 2vw;
}
.Product-View-Details div
{
    margin: 0.5vh 0vw;
}
.Product-View-Details .Product-Brand
{
    text-decoration: underline;
    color: gray;
}
.Product-View-Details .Product-Name
{
    font-size: 26px;
}
.Product-View-Rate-Details
{
    width: 35vw;
    height: auto;
    padding: 1vh 2vw;
}
.Product-View-Rate-Details .Product-View-Rate-Mrp
{
    color: gray;
    text-decoration: line-through;
    font-size: 14px;
}
.Product-View-Rate-Details .Product-View-Rate-Selling-Price
{
    font-size: 22px;
}
.Product-View-Rate-Details .Product-View-Rate-Offer
{
    font-size: 14px;
    color: #ba5252;
}
.Product-View-Rate-Details .Product-View-Rate-Tax
{
    color: gray;
    font-size: 12px;
}
.Product-View-Quantity
{
    width: 35vw;
    height: auto;
    padding: 1vh 2vw;
    display: flex;
    flex-direction: row;
}
.Product-View-Quantity .Product-View-Quantity-Select
{
    width: 3vw;
    height: 5vh;
}
.Product-View-Quantity-Select input
{
    width: 100%;
    height: 100%;
    font-size: 16px;
    padding: 1vh 0vw 1vh 0.5vw;
    color: gray;
    border: 1px solid #e1e1e1;
}
.Product-View-Quantity .Product-View-Quantity-Cart
{
    width: 12vw;
    height: 5vh;
    margin: 0 2vw;
}
.Product-View-Quantity .Product-View-Quantity-Fav
{
    width: 12vw;
    height: 5vh;
    margin: 0;
}
.Product-View-Quantity-Cart button
{
    width: 100%;
    height: 100%;
    font-size: 16px;
    font-weight: bold;
    background-color: #9cc63d;
    color: white;
    border: 1px solid #9cc63d;
    border-radius: 2px;
    cursor: pointer;
}
.Product-View-Quantity-Fav button
{
    width: 100%;
    height: 100%;
    font-size: 16px;
    font-weight: bold;
    background-color: #9cc63d;
    color: white;
    border: 1px solid #9cc63d;
    border-radius: 2px;
    cursor: pointer;
}
.Product-View-Quantity-Cart .Out-Of-Stock-Button
{
    width: 100%;
    height: 100%;
    font-size: 16px;
    font-weight: bold;
    background-color: #FFC400;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.Product-View-Rate
{
    width: 35vw;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 1vh 2vw;
}
.Product-View-Rate span
{
    color: grey;
}
.Product-View-Select-active
{
    width: 30vw;
    height: 5vh;
    display: flex;
    flex-direction: row;
    margin: 0.5vh 0;
    border: 1px solid #e1e1e1;
    border-radius: 4px;
    background-color: rgba(132,194,37,.2);
}
.Product-View-Select
{
    width: 30vw;
    height: 5vh;
    display: flex;
    flex-direction: row;
    margin: 0.5vh 0;
    border: 1px solid #e1e1e1;
    border-radius: 4px;
    background-color: white;
}
.Product-View-Select :hover
{
    cursor: pointer;
}
.Product-View-Select-Unit
{
    width: 4vw;
    height: 5vh;
    text-align: center;
    padding: 1vh;
}
.Product-View-Select-Rate
{
    width: 22vw;
    height: 5vh;
    text-align: center;
    padding: 1vh;
    font-size: 14px;
}
.Product-View-Select-Rate .Mrp-Price
{
    text-decoration: line-through;
}
.Product-View-Select-Rate .Offer
{
    color: rgb(185, 21, 21);
}
.Product-View-Select-Tick
{
    width: 4vw;
    height: 4.7vh;
    vertical-align: middle;
    text-align: center;
    padding: 0.5vh;
    font-size: 22px;
    border-left: 1px solid #e1e1e1;
    border-radius: 4px;
}
.Product-View-Select-active .Product-View-Select-Tick 
{
    background-color: #9cc63d;
}
.Product-View-Select .Product-View-Select-Tick
{
    background-color: white;
}
.Product-View-Select-Tick svg
{
    color: rgb(235, 234, 234);
}

@media only screen and (max-width: 750px){
.Product-View
{
    display: flex;
    flex-direction: column;
    width: 90vw;
    height: auto;
    margin: 0 5vw;
    border: 1px solid #e1e1e1;
}
.Product-View-Left
{
    width: 90vw;
    height: auto;
    display: flex;
    border-right: 1px solid #e1e1e1;
}
.Product-View-Left img
{
    width: 85vw;
    height: 30vh;
    margin: 2.5vh 2.5vw;
}
.Product-View-Right
{
    display: flex;
    flex-direction: column;
    width: 90vw;
    height: auto;
}
.Product-View-Details
{
    width: 90vw;
    height: auto;
    margin: 1vh 0;
    padding: 1vh 2vw;
}
.Product-View-Details div
{
    margin: 0vh 0vw;
}
.Product-View-Details .Product-Brand
{
    text-decoration: underline;
    color: gray;
}
.Product-View-Details .Product-Name
{
    font-size: 26px;
}
.Product-View-Rate-Details
{
    width: 90vw;
    height: auto;
    padding: 0vh 2vw;
}
.Product-View-Rate-Details .Product-View-Rate-Mrp
{
    color: gray;
    text-decoration: line-through;
    font-size: 14px;
}
.Product-View-Rate-Details .Product-View-Rate-Selling-Price
{
    font-size: 22px;
}
.Product-View-Rate-Details .Product-View-Rate-Offer
{
    font-size: 14px;
    color: #ba5252;
}
.Product-View-Rate-Details .Product-View-Rate-Tax
{
    color: gray;
    font-size: 12px;
}
.Product-View-Quantity
{
    width: 90vw;
    height: auto;
    padding: 1vh 2vw;
    display: flex;
    flex-direction: row;
}
.Product-View-Quantity .Product-View-Quantity-Select
{
    width: 10vw;
    height: 5vh;
}
.Product-View-Quantity-Select input
{
    width: 100%;
    height: 100%;
    font-size: 16px;
    text-align: center;
    color: gray;
    border: 1px solid #e1e1e1;
}
.Product-View-Quantity .Product-View-Quantity-Cart
{
    width: 40vw;
    height: 5vh;
    margin: 0 2vw;
}
.Product-View-Quantity-Cart button
{
    width: 100%;
    height: 100%;
    font-size: 16px;
    font-weight: bold;
    background-color: #9cc63d;
    color: white;
    border: 1px solid #9cc63d;
    border-radius: 2px;
}
.Product-View-Rate
{
    width: 90vw;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 1vh 2vw;
}
.Product-View-Rate span
{
    color: grey;
}
.Product-View-Select-active
{
    width: 85vw;
    height: 5vh;
    display: flex;
    flex-direction: row;
    margin: 0.5vh 0;
    border: 1px solid #e1e1e1;
    border-radius: 4px;
    background-color: rgba(132,194,37,.2);
}
.Product-View-Select
{
    width: 85vw;
    height: 5vh;
    display: flex;
    flex-direction: row;
    margin: 0.5vh 0;
    border: 1px solid #e1e1e1;
    border-radius: 4px;
    background-color: white;
}
.Product-View-Select :hover
{
    cursor: pointer;
}
.Product-View-Select-Unit
{
    width: 15vw;
    height: 5vh;
    text-align: center;
    padding: 1vh;
}
.Product-View-Select-Rate
{
    width: 60vw;
    height: 5vh;
    text-align: center;
    padding: 1vh;
    font-size: 14px;
}
.Product-View-Select-Rate .Mrp-Price
{
    text-decoration: line-through;
}
.Product-View-Select-Rate .Offer
{
    color: rgb(185, 21, 21);
}
.Product-View-Select-Tick
{
    width: 10vw;
    height: 4.7vh;
    vertical-align: middle;
    text-align: center;
    padding: 0.5vh;
    font-size: 22px;
    border-left: 1px solid #e1e1e1;
    border-radius: 4px;
}
.Product-View-Select-active .Product-View-Select-Tick 
{
    background-color: #9cc63d;
}
.Product-View-Select .Product-View-Select-Tick
{
    background-color: white;
}
.Product-View-Select-Tick svg
{
    color: rgb(235, 234, 234);
}
}