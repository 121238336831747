.About
{
  text-align: center;
  width: 93%;
  margin: auto;
}
.About-form
{
  min-height: 49vh;
  font-size: 15px;
  padding: 0px 15px;
  text-indent: 60px;
  word-spacing: 0.5em;
  text-align: left;
}