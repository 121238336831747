.Refund
{
  padding: 5px 100px;
  text-align: center;
}
.Refund-form 
{
  min-height: 50vh;
  font-size: 16px;
  padding: 0px 15px;
  word-spacing: 0.5em;
  text-align: left;
}
.Refund-form .form-control p b
{
  text-decoration: underline;
  font-size: 18px;
}