.Popup-Dropdown
{
  position: fixed;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.Popup-Inner-Dropdown
{
    position: absolute;
    left: 0%;
    right: 85%;
    top: 17%;
    bottom: auto;
    margin: auto;
    border: 1px solid whitesmoke;
    background-color: whitesmoke;
    border-radius: 5px;
}
.Popup-Dropdown-Login
{
  position: fixed;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.Popup-Inner-Dropdown-Login
{
    position: absolute;
    left: 85%;
    right: 0%;
    top: 4%;
    bottom: auto;
    margin: auto;
    border: 1px solid whitesmoke;
    background-color: whitesmoke;
    border-radius: 5px;
}
.Dropdown
{
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}
.Dropdown-List
{
  width: 100%;
  height: auto;
  color: black;
  padding: 5px 5px;
  transition: all 0s ease-in;
}
.Dropdown-List:hover
{
  cursor: pointer;
  background-color: blue;
  color: whitesmoke;
}
.Dropdown-List a
{
    color: black;
    text-decoration: none;
}
.Dropdown-List a:hover
{
  color: whitesmoke;
}