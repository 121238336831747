.Account
{
    display: inline-flex;
    width: 90%;
    height: auto;
    margin: 2% 5%;
    background-color: white;
}
.Left-Account-Content
{
    display: flex;
    flex-direction: column;
    width: 25%;
    height: auto;
}
.Left-Account-Content .Account-Name
{
    width: 90%;
    height: 10vh;
    margin: 5% 5%;
    background-color: whitesmoke;
    display: inline-flex;
    border-radius: 5px;
}
.Account-Image
{
    width: 30%;
    height: 100%;
}
.Account-Image img
{
    width: 90%;
    height: 90%;
    margin: 5% 5%;
}
.Account-Details
{
    width: 70%;
    height: 100%;
    padding: 0% 10%;
}
.Account-Details p b
{
    font-size: 18px;
}
.Account-Side-Navigation
{
    width: 90%;
    height: 60vh;
    margin: 0 5%;
    display: flex;
    flex-direction: column;
}
.Account-Side-Navigation .Side-Nav-Heading
{
    width: 100%;
    height: 13%;
    background-color: whitesmoke;
    text-align: left;
    padding: 2vh 1vw;
    font-size: 16px;
    font-weight: bold;
    color: grey;
    border-bottom: 1px solid whitesmoke;
    cursor: pointer;
}
.Side-Nav-Heading-Active
{
    width: 100%;
    height: 13%;
    background-color: rgb(207, 207, 241);
    text-align: left;
    padding: 2vh 1vw;
    font-size: 16px;
    font-weight: bold;
    color: blue;
    border-bottom: 1px solid whitesmoke;
}
.Right-Account-Content
{
    display: flex;
    width: 75%;
    height: 100%;
}
.Right-Account-Content-Details
{
    width: 95%;
    height: auto;
    background-color: whitesmoke;
    margin: 2% 1%;
    overflow-y: scroll;
    border-radius: 10px;
}
.Right-Account-Content-Details::-webkit-scrollbar
{
    display: none;
}
.Right-Account-Content-Details .Account-Heading
{
    width: 100%;
    height: auto;
    text-align: center;
    text-decoration: underline;
}
.Right-Account-Content-Details .Profile-Image
{
    width: 100%;
    height: 35%;
    text-align: center;
}
.Profile-Image img
{
    width: 25%;
    height: 100%;
    border-radius: 50%;
    border: 10px solid #4885FF;
}
.Profile-Details
{
    width: 90%;
    height: auto;
    margin: 5% 5%;
}
.Profile-Details .Details
{
    width: 90%;
    height: auto;
    margin: 0% 5%;
    line-height: 30px;
}
.Details b
{
    color: gray;
}
.Add-Icon
{
    width: 100%;
    text-align: right;
}
.Add-Icon button
{
    background-color: #4885FF;
    border: none;
    color: whitesmoke;
    font-size: 25px;
    border-radius: 5px;
    cursor: pointer;
}
.Right-Account-Content-Details .Shipping-Address-Container
{
    width: 100%;
    text-align: center;
}
.Account-Heading
{
    width: 100%;
    text-align: center;
}
.Input-Fields
{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.Input-Fields div
{
    width: 40%;
    display: flex;
    flex-direction: column;
    text-align: left;
    margin: 1% 5%;
}
.Input-Fields div label
{
    font-weight: bold;
    color: #626161;
    padding: 2% 0;
}
.Input-Fields div input
{
    width: 90%;
    border: none;
    border-bottom: 2px solid #ced4da;
    background-color: whitesmoke;
}
.Input-Fields div input:focus
{
    outline: none;
    border-bottom: 2px solid #4885FF;
}
.Input-Fields div select
{
    width: 90%;
    border: none;
    border-bottom: 2px solid #ced4da;
    background-color: whitesmoke;
}
.Input-Fields div select:focus
{
    outline: none;
}
.Save-Button
{
    width: 100%;
    margin: auto;
    text-align: center;
}
.Save-Button button
{
    width: 15%;
    background-color: #4885FF;
    color: whitesmoke;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 10px;
    padding: 8px 0;
}
.Reset-Password-Input-Fields
{
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    text-align: center;
}
.Reset-Password-Input-Fields div
{
    width: 50%;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 1% 25%;
}
.Reset-Password-Input-Fields div label
{
    text-align: left;
    font-weight: bold;
    color: #626161;
    padding: 2% 0;
}
.Reset-Password-Input-Fields div input
{
    width: 100%;
    border: none;
    border-bottom: 2px solid #ced4da;
}
.Reset-Password-Input-Fields div input:focus
{
    outline: none;
    border-bottom: 2px solid #4885FF;
}
.Profile-Image input
{
    display: none;
}
.Profile-Image label button
{
    position: relative;
    right: 5%;
    top: 30%;
    font-size: 20px;
    border-radius: 50%;
    color: white;
    background-color: black;
    cursor: pointer;
}
.Profile-Details-Edit-Profile
{
    width: 95%;
    height: auto;
    margin: 5% 5%;
}
.Profile-Details-Edit-Profile div
{
    width: calc(90%/3);
    display: inline-flex;
    margin: 0 1vw;
}
.Profile-Details-Edit-Profile div label
{
    text-align: left;
    font-weight: bold;
    color: #626161;
    padding: 2% 0;
}
.Profile-Details-Edit-Profile div input
{
    width: fit-content;
    border: none;
    background-color: whitesmoke;
    border-bottom: 2px solid #ced4da;
    padding: 0 10px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
}
.Reset-Password-Input-Fields div input
{
    background-color: whitesmoke;
}
.Update-Button
{
    width: 100%;
    margin: 2% 0;
    text-align: center;
}
.Update-Button button
{
    width: 15%;
    background-color: #4885FF;
    color: whitesmoke;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 10px;
    padding: 8px 0;
}