.Terms
{
  padding: 5px 50px;
  margin: 0 auto;
  text-align: center;
}
.Terms-form
{
  min-height: 50vh;
  font-size: 16px;
  padding: 0px 15px;
  word-spacing: 0.5em;
  text-align: left;
}
.Pop-Up
{
  width: 800px;
  height: auto;
  background-color: rgb(73, 73, 233);
  color:whitesmoke;
  border-radius: 10px;
  cursor: pointer;
}
.Pop-Up p
{
  padding: 5px 20px;
}
.Pop-Up .form-control
{
  background-color: #f1ecdf;
  color: black;
  border-radius: 10px;
}
.Pop-Up .form-control-unview
{
  display: none;
}