.Header .Main-Header
{
  display: flex;
  flex-direction: row;
}
.logo-div
{
  width: 20vw;
  height: 12vh;
  margin: 0vh 1vw;
  padding: 2vh 0;
  justify-content: center;
}
.logo
{
  width: 18vw;
  height: 9vh;
  max-height: 14vh;
  align-items: center;
}
.TopHeader
{
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: right;
  width: 90%;
  height: 4vh;
}
.TopHeader div:first-child
{
  background-color: rgb(84, 84, 233);
  width: auto;
  height: 100%;
  padding-top: 0.5vh;
  color: whitesmoke;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.TopHeader div
{
  padding: 0 1vw;
  font-size: 14px;
  font-weight: lighter;
  color: #666;
  cursor: pointer;
}
.TopHeader div a
{
  text-decoration: none;
  color: #666
}
.TopHeader div a:hover
{
  text-decoration: underline;
}
.TopHeader div span
{
  cursor: pointer;
}
.TopHeader div svg
{
  font-size: 18px;
}
.MiddleHeader
{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  width: 90vw;
  height: 8vh;
}
.Input-Section
{
  width: 78%;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 1.5vh 2vw;
}
.Search
{
  width: 70%;
  height: 90%;
  border-color: rgb(118, 118, 246);
  padding-left: 10px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.Input-Section button
{
  width: 5%;
  height: 90%;
  border-color: rgb(118, 118, 246);
  border-left: none;
  color: whitesmoke;
  background-color: rgb(84, 84, 233);
  font-weight: bold;
}
.Input-Section button svg
{
  width: 100%;
  height: 100%;
  color: whitesmoke;
}
.Icons
{
  width: 12%;
  height: 100%;
  display: flex;
  flex-direction: row-reverse;
}
.Icon
{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  border: solid 1px #f2f2f2;
  background-color: whitesmoke;
}
.Icon a div
{
  display: inline-flex;
  width: 50%;
  height: 100%;
}
.Icon a
{
  text-decoration: none;
}
.Icon a div svg
{
  width: 100%;
  height: 100%;
  padding: 1vh 1vw;
}
.Icon a div span
{
  width: 100%;
  height: 100%;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  padding: 1vh 0;
}
.BottomHeader
{
  display: inline-flex;
  width: 100vw;
  height: 5vh;
  background-color: whitesmoke;
  border: solid 1px rgb(233, 231, 231);
}
.Dropdown-Container div
{
  display: inline-flex;
}
.Dropdown-Button
{
  width: 15vw;
  height: 5vh;
  border: none;
  font-size: 16px;
  background-color: rgb(84, 84, 233);
  color: whitesmoke;
  padding: 1vh 1vw;
}
.Dropdown-Button:hover
{
  cursor: pointer;
}
.Dropdown-Button2
{
  width: 76vw;
  height: 5vh;
  border: none;
  font-size: 16px;
  justify-content: left;
  color: red;
  padding: 1vh 1vw;
  text-align: left;
  background-color: whitesmoke;
}
.Dropdown-Button2:hover
{
  cursor: pointer;
}
.Dropdown-Button2 svg
{
  color: black;
}

@media screen and (min-width:375px) and (max-width:912px){
  .Header
  {
    margin: 0 0;
    min-width: 100vw;
  }
  .Header .Main-Header
  {
    height: auto;
    display: flex;
    flex-direction: row;
  }
  .logo-div
  {
    width: 25vw;
    height: 8vh;
    margin: 0vh 1vw;
    padding: 1.5vh 1vw;
    justify-content: center;
  }
  .logo
  {
    width: 25vw;
    height: 4vh;
    max-height: 8vh;
    align-items: center;
  }
  .TopHeader
  {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: right;
    width: 75vw;
    height: 2vh;
  }
  .TopHeader div
  {
    padding: 0 1vw;
    font-size: 6px;
    font-weight: lighter;
    color: #666;
    cursor: pointer;
  }
  .TopHeader div a
  {
    text-decoration: none;
    color: #666
  }
  .TopHeader div a:hover
  {
    text-decoration: underline;
  }
  .TopHeader div span
  {
    cursor: pointer;
  }
  .TopHeader div svg
  {
    font-size: 12px;
  }
  .MiddleHeader
  {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    width: 75vw;
    height: 6vh;
  }
  .Input-Section
  {
    width: 70%;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: 1.5vh 2vw;
  }
  .Search
  {
    width: 80%;
    height: 85%;
    border-color: rgb(118, 118, 246);
    padding-left: 10px;
  }
  .Input-Section input::placeholder
  {
    font-size: 10px;
  }
  .Input-Section button
  {
    width: 14%;
    height: 85%;
    border-color: rgb(118, 118, 246);
    border-left: none;
    color: whitesmoke;
    background-color: rgb(84, 84, 233);
    font-weight: bold;
  }
  .Icons
  {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: row-reverse;
  }
  .Icon
  {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    border: solid 1px #f2f2f2;
    background-color: whitesmoke;
  }
  .Icon a div
  {
    display: inline-flex;
    width: 50%;
    height: 100%;
  }
  .Icon a
  {
    text-decoration: none;
  }
  .Icon a div svg
  {
    width: 100%;
    height: 100%;
    padding: 1.5vh 1.5vw;
  }
  .Icon a div span
  {
    width: 100%;
    height: 100%;
    font-size: 8px;
    font-weight: bold;
    text-align: center;
    padding: 1.5vh 0;
  }
  .BottomHeader
  {
    display: inline-flex;
    width: 100vw;
    height: 3vh;
    background-color: whitesmoke;
    border: solid 1px rgb(233, 231, 231);
  }
  .Dropdown-Container div
  {
    display: inline-flex;
  }
  .Dropdown-Button
  {
    width: 25vw;
    height: 3vh;
    border: none;
    font-size: 8px;
    background-color: rgb(84, 84, 233);
    color: whitesmoke;
    padding: 0.5vh 1vw;
  }
  .Dropdown-Button:hover
  {
    cursor: pointer;
  }
  .Dropdown-Button2
  {
    width: 70vw;
    height: 3vh;
    border: none;
    font-size: 8px;
    justify-content: left;
    color: red;
    padding: 0.5vh 2vw;
    text-align: left;
    background-color: whitesmoke;
  }
  .Dropdown-Button2:hover
{
  cursor: pointer;
}
  .Dropdown-Button2 svg
  {
    color: black;
  } 
}