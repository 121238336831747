.Button-Close-Location
{
  width: 25px;
  height: 25px;
  position: fixed;
  top: 4%;
  left: 82%;
  z-index: 1;
  background-color: whitesmoke;
  color: gray;
  border: none;
  font-size: 16px;
}
.Popup-Location
{
  position: fixed;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0,0,0, 0.5);
}
.Popup-Inner-Location
{
  position: absolute;
  left: 57%;
  right: 16%;
  top: 3%;
  margin: auto;
}
.Location
{
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}
.Location-Heading
{
  width: 100%;
  height: 10%;
  text-align: center;
  background-color: whitesmoke;
  color: #0D76FF;
}
.Location-Heading
{
  line-height: 0.5;
}
.Location-Form
{
  width: 100%;
  height: auto;
}
.Location-Form form
{
  padding: 30px 40px 10px 40px;
  background-color: white;
}
.Location-Form-Control
{
  margin-bottom: 15px;
  padding-bottom: 20px;
  position: relative;
  transform: translateY(3vh);
  animation: move 1s forwards
}
@keyframes move {
  100%{
    transform: translateY(0vw)
  }
}
.Location-Form-Control input
{
  border: none;
  border-bottom: 2px solid #e1e1e1;
  background-color: white;
  padding: 4px;
  font-size: 14px;
  font-family: inherit;
  display: block;
  width: 100%;
}
.Location-Form-Control input::placeholder
{
  color: #BBBBBB;
  font-size: 14px;
}
.Location-Submit
{
  width: 100%;
  height: auto;
}
.Location-Form-Control button
{
  color: whitesmoke;
  background: linear-gradient(to right, #66ccff 0%, #0000cc 100%);
  border-radius: 5px;
  padding: 5px;
  font-size: 16px;
  font-family: inherit;
  width: 40%;
}
.Location-Form-Control .Available
{
  color: #9cc63d;
}
.Location-Form-Control .Cost
{
  color: #0D76FF;
}
@media only screen and (max-width: 750px){
.Button-Close-Location
{
  width: 25px;
  height: 25px;
  position: fixed;
  top: 3%;
  left: 85%;
  z-index: 1;
  background-color: whitesmoke;
  color: gray;
  border: none;
  font-size: 16px;
}
.Popup-Location
{
  position: fixed;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0,0,0, 0.5);
}
.Popup-Inner-Location
{
  position: absolute;
  left: 4%;
  right: 4%;
  top: 2%;
  margin: auto;
}
.Location
{
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}
.Location-Heading
{
  width: 100%;
  height: 10%;
  text-align: center;
  background-color: whitesmoke;
  color: #0D76FF;
}
.Location-Heading
{
  line-height: 0.5;
}
.Location-Form
{
  width: 100%;
  height: auto;
}
.Location-Form form
{
  padding: 30px 40px 10px 40px;
  background-color: white;
}
.Location-Form-Control
{
  margin-bottom: 15px;
  padding-bottom: 20px;
  position: relative;
  transform: translateY(3vh);
  animation: move 1s forwards
}
@keyframes move {
  100%{
    transform: translateY(0vw)
  }
}
.Location-Form-Control input
{
  border: none;
  border-bottom: 2px solid #e1e1e1;
  background-color: white;
  padding: 4px;
  font-size: 14px;
  font-family: inherit;
  display: block;
  width: 100%;
}
.Location-Form-Control input::placeholder
{
  color: #BBBBBB;
  font-size: 14px;
}
.Location-Submit
{
  width: 100%;
  height: auto;
}
.Location-Form-Control button
{
  color: whitesmoke;
  background: linear-gradient(to right, #66ccff 0%, #0000cc 100%);
  border-radius: 5px;
  padding: 5px;
  font-size: 16px;
  font-family: inherit;
  width: 40%;
}
}