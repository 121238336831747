.Button-Close
{
  width: 25px;
  height: 25px;
  position: fixed;
  top: 21%;
  left: 62%;
  z-index: 1;
  background-color: rgb(92, 86, 86);
  color: #fff;
  border: none;
  border-radius: 18px;
}
.popup 
{
  position: fixed;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0,0,0, 0.5);
}
.popup_inner 
{
  position: absolute;
  left: 38%;
  right: 37%;
  top: 24%;
  bottom: auto;
  margin: auto;
  background-color: white;
  border-radius: 10px;
}
.Profile
{
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}
.Profile-Heading
{
  width: 100%;
  height: 15%;
  text-align: center;
  background-color: whitesmoke;
  color: #0D76FF;
  margin: 0 0 10px 0;
  border-radius: 10px;
}
.Profile-Form
{
  width: 100%;
  height: auto;
}
.Profile-Form .Profile-Form-Control
{
  padding: 15px 40px 15px 40px;
}
.Profile-Submit .Profile-Form-Control
{
  padding: 5px 40px 20px 40px;
}
.Profile-Form-Control
{
  margin-bottom: 5px;
  padding-bottom: 20px;
  position: relative;
  transform: translateY(3vh);
  animation: move 1s forwards;
  text-align: center;
}
.Profile-Form-Control button
{
  margin-bottom: 10px;
  border: none;
  margin: 10px 0;
}
@keyframes move {
  100%{
    transform: translateY(0vw)
  }
}
.Profile-Form-Control input
{
  border: none;
  border-bottom: 2px solid #e1e1e1;
  background-color: white;
  padding: 4px;
  font-size: 14px;
  font-family: inherit;
  display: block;
  width: 100%;
}
.Profile-Form-Control input::placeholder
{
  color: #BBBBBB;
  font-size: 14px;
}
.Profile-Submit
{
  width: 100%;
  height: auto;
}
.Profile-Form-Control button
{
  color: whitesmoke;
  background: linear-gradient(to right, #66ccff 0%, #0000cc 100%);
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  font-family: inherit;
  width: 100%;
}
.Profile-Form-Control span
{
  font-size: 14px;
  color: #625b5b;
  cursor: pointer;
  text-align: center;
  background-color: white;
}
.Profile-Form-Control span:hover
{
  text-decoration: underline;
}
@media only screen and (max-width: 750px){
  .Button-Close
{
  width: 25px;
  height: 25px;
  position: fixed;
  top: 24%;
  left: 91%;
  z-index: 1;
  background-color: rgb(92, 86, 86);
  color: #fff;
  border: none;
  border-radius: 18px;
}
.popup 
{
  position: fixed;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0,0,0, 0.5);
}
.popup_inner 
{
  position: absolute;
  left: 4%;
  right: 4%;
  top: 25%;
  bottom: auto;
  margin: auto;
}
.Profile
{
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}
.Profile-Heading
{
  width: 100%;
  height: 15%;
  text-align: center;
  background-color: whitesmoke;
  color: #0D76FF;
}
.Profile-Form
{
  width: 100%;
  height: auto;
}
.Profile-Form form
{
  padding: 30px 40px 10px 40px;
  background-color: white;
}
.Profile-Submit form
{
  padding: 5px 40px 20px 40px;
  background-color: white;
}
.Profile-Form-Control
{
  margin-bottom: 15px;
  padding-bottom: 20px;
  position: relative;
  transform: translateY(3vh);
  animation: move 1s forwards
}
@keyframes move {
  100%{
    transform: translateY(0vw)
  }
}
.Profile-Form-Control input
{
  border: none;
  border-bottom: 2px solid #e1e1e1;
  background-color: white;
  padding: 4px;
  font-size: 14px;
  font-family: inherit;
  display: block;
  width: 100%;
}
.Profile-Form-Control input::placeholder
{
  color: #BBBBBB;
  font-size: 14px;
}
.Profile-Submit
{
  width: 100%;
  height: auto;
}
.Profile-Form-Control button
{
  color: whitesmoke;
  background: linear-gradient(to right, #66ccff 0%, #0000cc 100%);
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  font-family: inherit;
  width: 100%;
}
.Profile-Form-Control span
{
  font-size: 14px;
  color: #625b5b;
  cursor: pointer;
  padding-right: 10px;
}
.Profile-Form-Control span:hover
{
  text-decoration: underline;
}
}