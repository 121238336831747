*
{
  box-sizing: border-box;
  font-family: 'Heebo', sans-serif;
  vertical-align: middle;
}
.App {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
@media only screen and (max-width: 750px){
*
{
  box-sizing: border-box;
  font-family: 'Heebo', sans-serif;
  vertical-align: middle;
}
.App {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
}