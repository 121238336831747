.Button-Close-Register
{
  width: 25px;
  height: 25px;
  position: fixed;
  top: 14%;
  left: 61%;
  z-index: 1;
  background-color: rgb(92, 86, 86);
  color: #fff;
  border: none;
  border-radius: 18px;
}
.Popup 
{
  position: fixed;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0,0,0, 0.5);
}
.Popup_Inner 
{
  position: absolute;
  left: 38%;
  right: 38%;
  top: 16%;
  bottom: 18%;
  margin: auto;
  background-color: white;
}
.Register
{
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}
.Register-Header
{
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  background-color: white;
}
.Register-Heading
{
  width: 50%;
  height: 15%;
  text-align: center;
  background-color: whitesmoke;
  color: #0D76FF;
  cursor: pointer;
  transition: all 1s ease-in;
}
.Register-Heading:hover
{
  box-shadow: 1px 0px 5px 4px rgba(187,190,191,1);
}
.Register-Form
{
  width: 100%;
  height: auto;
  padding: 30px 40px 10px 40px;
}
.Register-Form-Control
{
  margin-bottom: 15px;
  padding-bottom: 20px;
  position: relative;
  transform: translateY(3vh);
  animation: move 1s forwards;
}
.Register-Form-Control .CheckBox
{
  display: inline;
  width: 5%;
  margin: 0 15px;
}
.Register-Form-Control a
{
  text-decoration: none;
  color: #0D76FF;
}
@keyframes move {
  100%{
    transform: translateY(0vw)
  }
}
.Register-Form-Control input
{
  border: none;
  border-bottom: 2px solid #e1e1e1;
  background-color: white;
  padding: 4px;
  font-size: 14px;
  font-family: inherit;
  display: block;
  width: 100%;
}
.Register-Form-Control input::placeholder
{
  color: #BBBBBB;
  font-size: 14px;
}
.Register-Submit
{
  width: 100%;
  height: auto;
  padding: 5px 5px 20px 5px;
}
.Register-Form-Control button
{
  color: whitesmoke;
  background: linear-gradient(to right, #66ccff 0%, #0000cc 100%);
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  font-family: inherit;
  width: 100%;
}
.Register-Form-Control b
{
  font-size: 14px;
  color: #625b5b;
}
.Register-Form-Control p
{
  font-size: 14px;
  color: #918787;
}
.Register-Form-Control span
{
  font-size: 14px;
  color: #625b5b;
  cursor: pointer;
  padding-left: 30px;
}
.Register-Form-Control span:hover
{
  text-decoration: underline;
}
@media only screen and (max-width: 750px){
  .Button-Close-Register
{
  width: 25px;
  height: 25px;
  position: fixed;
  top: 24%;
  left: 91%;
  z-index: 1;
  background-color: rgb(92, 86, 86);
  color: #fff;
  border: none;
  border-radius: 18px;
}
.Popup 
{
  position: fixed;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0,0,0, 0.5);
}
.Popup_Inner 
{
  position: absolute;
  left: 4%;
  right: 4%;
  top: 25%;
  bottom: 25%;
  margin: auto;
}
.Register
{
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}
.Register-Header
{
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  background-color: white;
}
.Register-Heading
{
  width: 50%;
  height: 15%;
  text-align: center;
  background-color: whitesmoke;
  color: #0D76FF;
  cursor: pointer;
  transition: all 1s ease-in;
}
.Register-Heading:hover
{
  box-shadow: 1px 0px 5px 4px rgba(187,190,191,1);
}
.Register-Form
{
  width: 100%;
  height: auto;
}
.Register-Form form
{
  padding: 30px 40px 10px 40px;
  background-color: white;
}
.Register-Submit form
{
  padding: 5px 40px 20px 40px;
  background-color: white;
}
.Register-Form-Control
{
  margin-bottom: 15px;
  padding-bottom: 20px;
  position: relative;
  transform: translateY(3vh);
  animation: move 1s forwards
}
@keyframes move {
  100%{
    transform: translateY(0vw)
  }
}
.Register-Form-Control input
{
  border: none;
  border-bottom: 2px solid #e1e1e1;
  background-color: white;
  padding: 4px;
  font-size: 14px;
  font-family: inherit;
  display: block;
  width: 100%;
}
.Register-Form-Control input::placeholder
{
  color: #BBBBBB;
  font-size: 14px;
}
.Register-Submit
{
  width: 100%;
  height: auto;
}
.Register-Form-Control button
{
  color: whitesmoke;
  background: linear-gradient(to right, #66ccff 0%, #0000cc 100%);
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  font-family: inherit;
  width: 100%;
}
.Register-Form-Control b
{
  font-size: 14px;
  color: #625b5b;
}
.Register-Form-Control p
{
  font-size: 14px;
  color: #918787;
}
.Register-Form-Control span
{
  font-size: 14px;
  color: #625b5b;
  cursor: pointer;
  padding-left: 30px;
}
.Register-Form-Control span:hover
{
  text-decoration: underline;
}
}