.Contact
{
  text-align: center;
  width: 93%;
  margin: auto;
}
.Contact-Div
{
    text-align: left;
    display: inline-flex;
}
.Contact-Div .form-control
{
    padding-right: 80px;
}
.form-control select
{
  border: none;
  border-bottom: 2px solid blue;
  padding: 5px;
  font-size: 14px;
  font-family: inherit;
  display: block;
  width: 100%;
}
.form-control .Message
{
  height: 5vh;
  padding-right: 100px;
}
.Address-Info
{
  display: inline-flex;
  margin: 40px 40px;
}
.Address-Info .form-control
{
  padding-right: 80px;
}
